<template>
    <section id="Profit">
        <div class="dial">
            <input type="text" class="knob" :value="margins.ratio * 100">
            <div class="margin">
                <h2>{{ this.margins.profit >= 0 ? `$${ this.margins.profit }` : `-$${ Math.abs(this.margins.profit) }` }}</h2>
                <h4>CAD</h4>
            </div>
        </div>
        <div class="card">
            <h3>History</h3>
            <div>
                <div class="row">
                    <h4>Bets Placed</h4>
                    <h4 class="count">{{ this.margins.betsPlaced }}</h4>
                </div>
                <div class="row">
                    <h4>Bets Won</h4>
                    <h4 class="count">{{ this.margins.betsWon }}</h4>
                </div>
                <div class="row">
                    <h4>Biggest Win</h4>
                    <h4 class="count">${{ this.margins.biggestWin }}</h4>
                </div>
                <div class="row">
                    <h4>Parlays Placed</h4>
                    <h4 class="count">{{ this.margins.parlaysPlaced }}</h4>
                </div>
                <div class="row">
                    <h4>Parlays Won</h4>
                    <h4 class="count">{{ this.margins.parlaysWon }}</h4>
                </div>
            </div>
        </div>
        <Options :options="options" :selected="3" />
    </section>
</template>

<script>

    // Components.
    import Options from '@/components/Options.vue';

    // Firebase.
    import { getBet, getWager } from '@/firebase/wagers.js';
    import { getBook } from '@/firebase/books.js';

    // Misc.
    import { getTicketStatus, getBetStatus, adjustedOdds } from '@/utils';
    import { BETS_OPTIONS } from '@/constants.js';

    export default {
        name: 'Profit',
        components: {
            Options,
        },
        data() {
            return {
                bets: [],
                wagers: {},
                books: {},
            }
        },
        computed: {
            options() {
                return BETS_OPTIONS;
            },
            margins() {

                let profit        = 0;
                let atStake       = 0;
                let betsPlaced    = 0;
                let betsWon       = 0;
                let biggestWin    = 0;
                let parlaysPlaced = 0;
                let parlaysWon    = 0;

                for (const ticket of this.bets) {
                    if (!ticket.void) {

                        const ticketStatus = getTicketStatus(ticket, this.wagers);

                        if (ticketStatus === 'loss') {

                            profit -= ticket.options.risk;
                            atStake += ticket.options.risk;
                            betsPlaced++;

                            if (ticket.options.bets.length > 1) parlaysPlaced++;

                        } else if (ticketStatus === 'win') {

                            const payout = ((ticket.options.risk * this.adjustedOdds(ticket)) - ticket.options.risk);

                            profit += payout;
                            atStake += payout;
                            betsPlaced++;
                            betsWon++;

                            if (ticket.options.bets.length > 1) {
                                parlaysPlaced++;
                                parlaysWon++;
                            }

                            if (payout > biggestWin) biggestWin = payout;

                        }

                    }
                }

                return {
                    profit: profit.toFixed(2),
                    ratio: ((atStake / 2) + (profit / 2)) / atStake,
                    betsPlaced,
                    betsWon,
                    biggestWin: biggestWin.toFixed(2),
                    parlaysPlaced,
                    parlaysWon
                };

            }
        },
        mounted() {
            this.$store.dispatch('setNavShadow', false);
            $('.knob').knob({
                min: 0,
                max: 100,
                angleArc: 270,
                angleOffset: -135,
                thickness: 0.1,
                readOnly: true,
                lineCap: 'round',
                bgColor: '#E1E1E1',
                fgColor: this.margins.profit >= 0 ? '#D2AD32' : '#B83939',
                width: window.innerWidth - 40,
                height: window.innerWidth - 40,
            });
        },
        created() {
            if (this.profile.bets) {

                for (let bet of Object.keys(this.profile.bets)) {

                    this.$store.dispatch('updateLoading', 1);

                    getBet(bet).then(async snapshot => {

                        const val = snapshot.val();

                        for (let b of val.options.bets) {
                            if (!this.wagers[b.wager[0]]) {
                                this.wagers[b.wager[0]] = (await getWager(b.wager[0])).val();
                            }
                        }

                        this.books[val.book] = (await getBook(val.book)).val();
                        this.bets.push(val);

                        $('.knob').val(this.margins.ratio * 100).trigger('change').trigger('configure', {
                            fgColor: this.margins.profit >= 0 ? '#D2AD32' : '#B83939',
                        });

                        this.$store.dispatch('updateLoading', -1);

                    });

                }
            }
        },
        methods: {
            adjustedOdds(ticket) {
                return adjustedOdds(ticket, this.wagers);
            }
        }
    }

</script>

<style lang="scss" scoped>

    @import '../styles/_variables.scss';

    #Profit {
        padding: 0 0 50px + size(Medium);
    }

    .dial {
        position: relative;
        padding: size(Medium);
    }

    input {
        display: none;
    }

    .margin {

        text-align: center;

        position: absolute;
        top: 50%;
        left: 50%;

        transform: translateX(-50%) translateY(-50%);

    }

    .margin h2 {
        font-size: 15vw;
        font-weight: 700;
        text-shadow: 0 5px 10px color(Black, 0.15);
    }

    .margin h4 {

        font-size: 7.5vw;
        font-weight: 700;

        margin-top: size(Micro);

    }

    .card {
        padding: size(Medium);
        margin-top: -10vw;
    }

    .card h3 {
        padding-bottom: size(Medium);
        border-bottom: 1px solid color(Emperor, 0.15);
    }

    .card .row {

        display: flex;
        align-items: center;
        justify-content: space-between;

        margin-top: size(Medium);

    }

    .count {
        color: color(OldGold);
    }

</style>
