export const MANAGE_OPTIONS = [
    {
        icon: 'members',
        name: 'Members',
        link: { name: 'Members', params: {} },
    },
    {
        icon: 'wagers',
        name: 'Wagers',
        link: { name: 'Wagers', params: {} },
    },
    {
        icon: 'tickets',
        name: 'Tickets',
        link: { name: 'Tickets', params: {} },
    },
    {
        icon: 'profit',
        name: 'Margins',
        link: { name: 'Margins', params: {} },
    },
];

export const BETS_OPTIONS = [
    {
        icon: 'open',
        name: 'Open',
        link: { name: 'Bets', params: {} },
    },
    {
        icon: 'settled',
        name: 'Settled',
        link: { name: 'SettledBets', params: {} },
    },
    {
        icon: 'voided',
        name: 'Voided',
        link: { name: 'VoidedBets', params: {} },
    },
    {
        icon: 'profit',
        name: 'Profit',
        link: { name: 'Profit', params: {} },
    },
];
